import React, {useEffect, useMemo} from "react";
import {withGlobalStore} from "../../stores/GlobalStore";
import {useHistory, withRouter} from "react-router";
import Helper from "../../helpers/helper";
import {Modal} from "../../shared";
import {find as _find, cloneDeep as _cloneDeep, findIndex as _findIndex} from "lodash";
import "../../assets/scss/components/envelope-settings/envelope-settings.scss";
import {useAlertConditionStoreActions, useSelectedTab} from "../../stores/zustand/AlertConditionStore";
import ChartTemplate from "./components/chartTemplate/chartTemplate";
import AlertTabs from "./shared/alertTabs";
import {ALERT_TABS, ALERT_TABS_NAME} from "./constants/tabs";
import AlertModalHeader from "./shared/alertModalHeader";
import {useChartComputed, useChartType} from "./hooks/helpers/chart";
import {useEquipmentByParams} from "../../hooks/api/equipment/useEquipmentQuery";
import {useChartTypesStore} from "../../stores/zustand/ChartSelectedChartTypeStore";
import useAxisLabelQuery from "../../hooks/api/axisLabel/useAxisLabelQuery";
import useCustomChartTypes from "../../hooks/api/Chart/UseCustomChartTypes";
import {useInstallationPoint} from "./hooks/useInstallationPoint";
import {getSeriesList} from "../../pages/chart/highcharts/helpers/getSeriesList";

const ManageAlertModal = () => {
    const history = useHistory();
    const {equipmentItem: equipment} = useEquipmentByParams();
    const {axisLabelsChart: axisLabels} = useAxisLabelQuery(equipment.id);
    const chartTypes = useChartTypesStore();
    const {isChartFFT, isTach} = useChartType(chartTypes);
    const {setConfirmRemove} = useAlertConditionStoreActions();
    const onClose = () => {
        setConfirmRemove(false);
        history.push(Helper.deleteHashParams(["modal", "type", "axisId", "installationPointId", "fftAlertType"]));
    };
    const {chartType} = useChartComputed(chartTypes);
    const installationPoint = useInstallationPoint(equipment, chartType);
    const {customChartTypes, isLoading: isCustomChartTypesLoading} = useCustomChartTypes(equipment.id, chartTypes);
    const isAdapterChartType = customChartTypes?.[chartType] || installationPoint.universalAdapter;

    const {setTab, flushStore} = useAlertConditionStoreActions();
    const {isTachometerExist} = useChartComputed(chartTypes, equipment);

    const tabsConfig = useMemo(() => {
        const tabsLocal = _cloneDeep(ALERT_TABS);

        if (!isTachometerExist || isAdapterChartType) {
            const index = _findIndex(tabsLocal, {key: ALERT_TABS_NAME.TACHOMETER_RPM});
            tabsLocal.splice(index, 1);
        }
        if (isAdapterChartType) {
            const index = _findIndex(tabsLocal, {key: ALERT_TABS_NAME.DELTA_T});
            tabsLocal.splice(index, 1);
        }

        return tabsLocal;
    }, [isAdapterChartType, isTachometerExist]);

    const currentTab = useSelectedTab();
    const CurrentTab = useMemo(() => {
        return _find(ALERT_TABS, {key: currentTab})?.component || (() => <></>);
    }, [currentTab]);

    useEffect(() => flushStore, []);
    let seriesList = getSeriesList(chartType, false, chartTypes);

    return (
        <Modal
            className="custom-modal manage-alerts-modal modal-manage-alert modal-xl"
            bodyClass="d-flex align-items-start"
            title={"Manage " + seriesList.label + " Alerts"}
            size="manage-alert"
            showModal={true}
            withoutFooter={true}
            onClose={onClose}
            inProgress={false}
            loader={false}
        >
            <div className="mb-3 col-5">
                {!isChartFFT && !isTach && !isAdapterChartType && !isCustomChartTypesLoading && (
                    <>
                        <AlertModalHeader
                            chartTypes={chartTypes}
                            equipment={equipment}
                        />
                        <AlertTabs
                            tabConfig={tabsConfig}
                            value={currentTab}
                            onTabChange={setTab}
                        />
                    </>
                )}
                <CurrentTab
                    equipment={equipment}
                    chartTypes={chartTypes}
                    axisLabels={axisLabels}
                />
            </div>
            <div className="col-7 chart-block">
                <ChartTemplate
                    equipment={equipment}
                    chartTypes={chartTypes}
                />
            </div>
        </Modal>
    );
};
export default withGlobalStore(withRouter(ManageAlertModal));
