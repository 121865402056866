import React, {useEffect, useRef, useState} from "react";
import {UncontrolledTooltip} from "reactstrap";
import PropTypes from "prop-types";
import "./infoTooltip.scss";
import {get as _get} from "lodash";

const SIZES = {
    base: "",
    xl: "tooltip-xl",
    xxl: "tooltip-xxl",
};

const ALIGN = {
    center: "",
    left: "tooltip-left",
};

const ICONS = {
    warning: "fa-exclamation-circle",
    info: "fa-info-circle",
};

const ICON_MAP = {
    warning: "warning-tooltip",
    info: "blue-tooltip",
};

const InfoTooltip = ({children, placement, size = "base", align = "center", variant = "info"}) => {
    const [isShowTooltip, setIsShowTooltip] = useState(false);
    const tooltipBodyRef = useRef();

    useEffect(() => {
        if (!tooltipBodyRef) {
            return;
        }
        setIsShowTooltip(true);
    }, [tooltipBodyRef.current]);

    return (
        <>
            <span
                className="mr-2"
                ref={tooltipBodyRef}
            >
                <i className={`fa ${_get(ICONS, variant, "fa-info-circle")} ${ICON_MAP[variant]}`}></i>
            </span>
            {isShowTooltip && (
                <UncontrolledTooltip
                    popperClassName={`${_get(SIZES, size, "")} ${_get(ALIGN, align, "")}`}
                    placement={placement ?? "top"}
                    target={tooltipBodyRef.current}
                >
                    {children}
                </UncontrolledTooltip>
            )}
        </>
    );
};
InfoTooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
    iconClass: PropTypes.string,
    placement: PropTypes.string,
    size: PropTypes.string,
    align: PropTypes.string,
    variant: PropTypes.oneOf(["info", "warning"]),
};

export default InfoTooltip;
