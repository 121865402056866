import React, {useState} from "react";
import {Modal, ValidationError} from "../../../shared";
import FormValidator from "../../../helpers/form-validator";
import PropTypes from "prop-types";
import {find as _find, get as _get} from "lodash";
import InstallationPointApi from "../../../api/installationPoint";
import Toast from "../../../pages/shared/toast";
import {IDLE_THRESHOLD_TYPES} from "../../../constants/constants";
import Helper from "../../../helpers/helper";
import EquipmentApi from "../../../api/equipment";
import SensorSelect from "../../../pages/shared/sensor-select";
import {withRouter} from "react-router";
import SelectWrapper from "../../../helpers/select-wrapper";
import "../../../assets/scss/components/chart/threshold-modal.scss";
import InfoTooltip from "../../../shared/infoTooltip/infoTooltip";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useEquipmentInvalidate} from "../../../hooks/api/equipment/useEquipmentInvalidate";
import {useSelectedByEquipment} from "../../../stores/zustand/ChartSelectedEquipmentStore";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";

import "./setIdleThreshold.scss";

const rules = [
    {
        field: "idle_threshold",
        method: "isFloat",
        validWhen: true,
        skipOnEmpty: false,
        message: "A value of 0 or higher is required.",
        options: {min: 0},
    },
    {
        field: "idle_threshold_type",
        method: "isEmpty",
        validWhen: false,
        message: "Threshold type can't be empty.",
    },
    {
        field: "impact_idle_threshold",
        method: "isFloat",
        validWhen: true,
        skipOnEmpty: false,
        message: "A value of 0 or higher is required.",
        options: {min: 0},
    },
    {
        field: "impact_idle_threshold_type",
        method: "isEmpty",
        validWhen: false,
        message: "Threshold type can't be empty.",
    },
];

const validator = new FormValidator(rules);

const SETTING_TYPES = {
    EQUIPMENT: "equipment",
    POINT: "point",
};

export const SetIdleThresholdModal = ({title, showModal, onClose}) => {
    const {equipmentItem} = useEquipmentByParams();
    const invalidateEquipment = useEquipmentInvalidate(equipmentItem.id);
    const chartTypes = useChartTypesStore();
    const equipmentSelected = useSelectedByEquipment(equipmentItem.id);
    const installation_point_id = +equipmentSelected.at(0) || equipmentItem.installationPoints.at(0).id;
    const pointData = _find(equipmentItem.installationPoints, {id: installation_point_id});

    const hasImpactVue = equipmentItem?.installationPoints?.some((item) => !!item?.sensor?.supportHfdvue && !!item?.sensor?.is_hfdvue);

    const data = {
        installation_point_id: installation_point_id,
        idle_threshold: equipmentSelected.length > 1 ? equipmentItem.idle_threshold : pointData.idle_threshold,
        idle_threshold_type: equipmentSelected.length > 1 ? equipmentItem.idle_threshold_type : pointData.idle_threshold_type,
        impact_idle_threshold: equipmentSelected.length > 1 ? equipmentItem.impact_idle_threshold : pointData.impact_idle_threshold,
        impact_idle_threshold_type: equipmentSelected.length > 1 ? equipmentItem.impact_idle_threshold_type : pointData.impact_idle_threshold_type,
        type: equipmentSelected.length > 1 ? SETTING_TYPES.EQUIPMENT : SETTING_TYPES.POINT,
    };

    const [state, setState] = useState({
        inProgress: false,
        data: data,
        validation: validator.valid(),
    });

    const onSubmit = () => {
        setState({...state, inProgress: true});

        const validation = validator.validate(state.data);

        if (validation.isValid) {
            EquipmentApi.checkService(equipmentItem.id).then(() =>
                InstallationPointApi.changeIdleThreshold(state.data.installation_point_id, state.data).then((response) => {
                    if (response.status === "ok") {
                        invalidateEquipment().then(() => {
                            Toast.success("The idle threshold has been successfully updated.", {
                                autoClose: 10000,
                                closeOnClick: true,
                                pauseOnHover: false,
                            });
                            setState({...state, inProgress: false});
                            onClose();
                        });
                    } else if (response.errors) {
                        Object.keys(response.errors).map((key) => {
                            validation[key].isValid = false;
                            validation[key].message = response.errors[key];
                        });

                        setState({
                            ...state,
                            validation,
                            inProgress: false,
                        });
                    }
                })
            );
        } else {
            setState({...state, inProgress: false, validation: validation});
        }
    };

    const handleChange = (e) => {
        const data = {...state.data};
        const name = _get(e, "target.name");
        const value = _get(e, "target.value");

        data[name] = value;
        if (name === "type") {
            if (value === SETTING_TYPES.EQUIPMENT) {
                data.idle_threshold = equipmentItem.idle_threshold;
                data.idle_threshold_type = equipmentItem.idle_threshold_type;
                data.impact_idle_threshold = equipmentItem.impact_idle_threshold;
                data.impact_idle_threshold_type = equipmentItem.impact_idle_threshold_type;
            } else if (value === SETTING_TYPES.POINT) {
                const pointData = _find(equipmentItem.installationPoints, {id: data.installation_point_id});
                data.idle_threshold = pointData?.idle_threshold || "";
                data.idle_threshold_type = pointData?.idle_threshold_type || "";
                data.impact_idle_threshold = pointData?.impact_idle_threshold || "";
                data.impact_idle_threshold_type = pointData?.impact_idle_threshold_type || "";
            }
        }

        setState({
            ...state,
            data: data,
            validation: validator.validate(data, name),
        });
    };

    const setInstallationPoint = (e) => {
        const installation_point_id = +e.target.value;

        Helper.setHashParams({
            modal: "idle-threshold",
            id: installation_point_id,
        });

        const pointData = _find(equipmentItem.installationPoints, {id: installation_point_id});

        setState({
            ...state,
            data: {
                installation_point_id: installation_point_id,
                idle_threshold: pointData?.idle_threshold || "",
                idle_threshold_type: pointData?.idle_threshold_type || "",
                impact_idle_threshold: pointData?.impact_idle_threshold || "",
                impact_idle_threshold_type: pointData?.impact_idle_threshold_type || IDLE_THRESHOLD_TYPES.ACC,
                type: SETTING_TYPES.POINT,
            },
        });
    };

    const colWidth = state.data.type === SETTING_TYPES.POINT ? 4 : 6;
    return (
        <Modal
            title={title}
            showModal={showModal}
            size={"lg"}
            onClose={onClose}
            inProgress={state.inProgress}
            className={"custom-modal"}
            onSubmit={onSubmit}
        >
            <div className="row">
                <div className="form-group col-6 mb-2">
                    <label>Asset Code:</label>
                    <p className="mb-2">{equipmentItem.asset_code}</p>
                </div>
                <div className="form-group col-6 mb-2">
                    <label>Equipment:</label>
                    <p className="mb-2">{equipmentItem.name}</p>
                </div>
            </div>
            <hr />
            <div className="row">
                {state.data.type === SETTING_TYPES.POINT && (
                    <div className={`form-group col-md-${colWidth}`}>
                        <label>Sensor</label>
                        <SensorSelect
                            value={state.data.installation_point_id}
                            equipment={equipmentItem}
                            filterOutTachometers={true}
                            onChange={setInstallationPoint}
                            disabled={state.inProgress}
                        />
                    </div>
                )}
                <div className={`form-group col-md-${colWidth}`}>
                    <label>Idle threshold:</label>
                    <InfoTooltip placement="right">
                        RMS readings below the specified idle threshold level will reduce the number of TWF/FFT readings being taken until the RMS readings
                        exceed the idle threshold. The minimum idle threshold value is 0.
                    </InfoTooltip>
                    <div className="input-group-append idle-threshold-block">
                        <input
                            className={
                                "form-control idle-threshold-input" +
                                (state.validation.idle_threshold.isValid || !state.validation.idle_threshold.message ? "" : " is-invalid")
                            }
                            type="number"
                            value={state.data.idle_threshold}
                            name="idle_threshold"
                            onChange={handleChange}
                            disabled={state.inProgress}
                        />
                        <span className="input-group-text negative-ml">
                            {Helper.getIdleThresholdMeasure(chartTypes, state.data.idle_threshold_type, "N/A")}
                        </span>
                        <div className="errorWrapper">
                            <ValidationError message={state.validation.idle_threshold.message} />
                        </div>
                    </div>
                </div>
                <div className={`form-group col-md-${colWidth} mb-4`}>
                    <label>Threshold type:</label>
                    <SelectWrapper
                        className={state.validation.idle_threshold_type.isValid || !state.validation.idle_threshold_type.message ? "" : " is-invalid"}
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        name="idle_threshold_type"
                        defaultValue={state.data.idle_threshold_type}
                        onChange={handleChange}
                        disabled={state.inProgress}
                    >
                        <option value="">---</option>
                        {Object.values(IDLE_THRESHOLD_TYPES).map((type) => (
                            <option
                                key={type}
                                value={type}
                            >
                                {Helper.capitalize(type)}
                            </option>
                        ))}
                    </SelectWrapper>
                    <div className="errorWrapper">
                        <ValidationError message={state.validation.idle_threshold_type.message} />
                    </div>
                </div>
            </div>
            {hasImpactVue && (
                <div className="row">
                    {state.data.type === SETTING_TYPES.POINT && <div className={`form-group col-md-${colWidth}`} />}
                    <div className={`form-group col-md-${colWidth}`}>
                        <label>ImpactVue Idle threshold:</label>
                        <InfoTooltip placement="right">
                            RMS readings below the specified idle threshold level will reduce the number of TWF/FFT readings being taken until the RMS
                            readings exceed the idle threshold. The minimum idle threshold value is 0.
                        </InfoTooltip>
                        <div className="input-group-append idle-threshold-block">
                            <input
                                className={
                                    "form-control idle-threshold-input" +
                                    (state.validation.impact_idle_threshold.isValid || !state.validation.impact_idle_threshold.message
                                        ? ""
                                        : " is-invalid")
                                }
                                type="number"
                                value={state.data.impact_idle_threshold}
                                name="impact_idle_threshold"
                                onChange={handleChange}
                                disabled={state.inProgress}
                            />
                            <span className="input-group-text negative-ml">
                                {Helper.getIdleThresholdMeasure(chartTypes, state.data.impact_idle_threshold_type, "N/A")}
                            </span>
                            <div className="errorWrapper">
                                <ValidationError message={state.validation.impact_idle_threshold.message} />
                            </div>
                        </div>
                    </div>
                    <div className={`form-group col-md-${colWidth} mb-4`}>
                        <label>ImpactVue Threshold type:</label>
                        <SelectWrapper
                            className={
                                state.validation.impact_idle_threshold_type.isValid || !state.validation.impact_idle_threshold_type.message
                                    ? ""
                                    : " is-invalid"
                            }
                            style={{
                                width: "100%",
                                display: "block",
                            }}
                            name="impact_idle_threshold_type"
                            defaultValue={IDLE_THRESHOLD_TYPES.ACC}
                            onChange={handleChange}
                            disabled={true}
                        >
                            <option value="">---</option>
                            {Object.values(IDLE_THRESHOLD_TYPES).map((type) => (
                                <option
                                    key={type}
                                    value={type}
                                >
                                    {Helper.capitalize(type)}
                                </option>
                            ))}
                        </SelectWrapper>

                        <ValidationError message={state.validation.impact_idle_threshold_type.message} />
                    </div>
                </div>
            )}
            <div className="row form-group">
                <label className="col-2">Set For:</label>
                <div className="axis-block-container col-10">
                    <div
                        className={`axis-block ${state.data.type === SETTING_TYPES.POINT && "active"}`}
                        onClick={() =>
                            handleChange({
                                target: {
                                    name: "type",
                                    value: SETTING_TYPES.POINT,
                                    tagName: "",
                                },
                            })
                        }
                    >
                        <span>Installation Point Only</span>
                    </div>
                    <div
                        className={`axis-block ${state.data.type === SETTING_TYPES.EQUIPMENT && "active"}`}
                        onClick={() =>
                            handleChange({
                                target: {
                                    name: "type",
                                    value: SETTING_TYPES.EQUIPMENT,
                                    tagName: "",
                                },
                            })
                        }
                    >
                        <span>For This Equipment</span>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

SetIdleThresholdModal.propTypes = {
    title: PropTypes.string,
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
};

export default withRouter(SetIdleThresholdModal);
