import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../../../tooltip/tooltip";

import styles from "./style.module.scss";
import {useHistory} from "react-router-dom";
import ButtonNavigation from "../buttonNavigation/buttonNavigation";

const EquipmentNavigation = ({data}) => {
    const history = useHistory();
    return (
        <div className="breadcrumbs__item">
            <div className={styles.stick}></div>
            {data?.currentIndexInLocation} OF {data?.totalInLocation}
            <Tooltip text={data?.previous?.name}>
                <ButtonNavigation
                    onClick={() => history.push(`/chart/${data?.previous?.id}`)}
                    disabled={!data?.previous}
                    variant="rotate"
                />
            </Tooltip>
            <Tooltip text={data?.next?.name}>
                <ButtonNavigation
                    onClick={() => history.push(`/chart/${data?.next?.id}`)}
                    disabled={!data?.next}
                />
            </Tooltip>
            <div className={styles.stick}></div>
        </div>
    );
};

EquipmentNavigation.propTypes = {
    data: PropTypes.object,
};

export default EquipmentNavigation;
