import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import ApiGateways from "../../../api/gateway";
import Node from "./node";
import Mote from "./mote";
import SearchInput from "../../shared/search-input";
import {Button, UncontrolledTooltip} from "reactstrap";
import Helper from "../../../helpers/helper";
import BatteryVoltageModal from "../../../modals/battery-voltage";
import {find as _find} from "lodash";
import {Loader, ValidationError} from "../../../shared";
import {get as _get, set as _set, map as _map, filter as _filter, cloneDeep as _cloneDeep} from "lodash";
import {withGlobalStore} from "../../../stores/GlobalStore";

import "../../../assets/scss/components/signal-lvl/signal-lvl.scss";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import ReadingIntervalInput from "../../shared/intervals/ReadingIntervalInput";
import BridgeChannelStats from "./bridge-channel-stats";
import {HeaderSimple} from "../../../shared/header";
import Toast from "../../shared/toast";
import {GATEWAY_MAX_CHANNEL_NUMBER, MOTES_LOT_DATA} from "../../../constants/constants";
import Select from "react-select";
import InfoTooltip from "../../../shared/infoTooltip/infoTooltip";
import SelectWrapper from "../../../helpers/select-wrapper";
import FormValidator from "../../../helpers/form-validator";
import SweetAlert from "react-bootstrap-sweetalert";
import ImageToolbar from "../../../widgets/ImageToolbar";
import {FirmwareVersion} from "../../../shared/FirmwareVersion/FirmwareVersion";

const rules = [
    {
        field: "channel_number",
        method: "isFloat",
        options: {min: 0, max: GATEWAY_MAX_CHANNEL_NUMBER},
        validWhen: true,
        skipOnEmpty: true,
        message: "This field requires a numeric value within the range of 0 and " + GATEWAY_MAX_CHANNEL_NUMBER + ".",
    },
    {
        field: "name",
        method: (name) => !!name.replace(/<[^>]*>/gi, "").replace(/[\s?&nbsp;]+/, "").length,
        validWhen: true,
        message: "This field is required.",
    },
    {
        field: "mode_revert_time",
        method: (name, options, state) => {
            return validateModeRevertTime(state);
        },
        validWhen: false,
        message: "This field is required.",
    },
];

const validator = new FormValidator(rules);

const validateModeRevertTime = (state) => {
    const mode = +_get(state, "mode");
    const modeRevertTime = +_get(state, "mode_revert_time");
    const InstallationMode = 2;

    return mode === InstallationMode && modeRevertTime === 0;
};

const ViewGateway = (props) => {
    const {history, match, user, auth} = props;

    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [loader, setLoader] = useState(true);
    const [item, setItem] = useState(null);
    const [globalSearchString, setGlobalSearchString] = useState("");
    const [inProgress, setInProgress] = useState(false);
    const [clearingData, setClearingData] = useState(false);
    const [locationFacilityId, setLocationFacilityId] = useState(null);
    const [validation, setValidation] = useState(validator.valid());

    const [modeOptions, setModeOptions] = useState({});
    const [modeRevertTimeOptions, setModeRevertTimeOptions] = useState({});

    const [searchParams, setSearchParams] = useState(Helper.getHashParams());
    const [hasOnlyDefaultImage, setHasOnlyDefaultImage] = useState(false);
    const [channelToChange, setChannelToChange] = useState(null);
    const [usedChannelsAtFacility, setUsedChannelsAtFacility] = useState([]);

    const version = item?.version;

    useEffect(() => {
        setSearchParams(Helper.getHashParams());

        setModeOptions(
            _map(_get(item, "gatewaySettingsReferences.mode_options", []), (item) => {
                return {value: item.id, label: item.value};
            })
        );

        setModeRevertTimeOptions(
            _map(_get(item, "gatewaySettingsReferences.mode_revert_time", []), (item) => {
                return {value: item.id, label: item.value, isDisabled: +item.id === 0};
            })
        );

        setHasOnlyDefaultImage(Helper.hasOnlyDefaultImage(_get(item, "photos", [])));
        setUsedChannelsAtFacility(_get(item, ["usedChannelsAtFacility"], []));
    }, [item]);

    useEffect(() => {
        if (item === null) {
            ApiGateways.view(match.params.serial).then(({item}) => {
                setItem(item);
                setLocationFacilityId(_get(item, "locationFacilityId"));
                setBreadcrumbs([{name: "Manage Gateways", link: "/network/gateways"}, {name: "Gateway " + item.serial}]);
                setLoader(false);
            });
        }
    }, []);

    const handleChangeGlobalSearch = (globalSearchString) => {
        setGlobalSearchString(globalSearchString);
    };

    const detachAllImages = () => {
        ApiGateways.detachAllImages(item.serial).then((response) => {
            let updatedItem = _cloneDeep(item);
            _set(updatedItem, "photos", response.images || []);

            setItem(updatedItem);
        });
    };

    const attachImages = (images) => {
        ApiGateways.attachImages(item.serial, {images}).then((response) => {
            if ((response.loaded || {}).images) {
                let updatedItem = _cloneDeep(item);
                _set(updatedItem, "photos", response.loaded.images);

                setItem(updatedItem);
            }
        });
    };

    const detachImage = (image) => {
        ApiGateways.detachImage(item.serial, {id: (image || {}).id}).then((response) => {
            let updatedItem = _cloneDeep(item);
            updatedItem.photos = response.images || [];

            setItem(updatedItem);
        });
    };

    const handleSubmit = () => {
        const validation = validator.validate(item);

        if (!validation.isValid) {
            setValidation(validation);
            return false;
        }

        setInProgress(true);

        if (clearingData) {
            _set(item, "location", "");
        }

        ApiGateways.update(item.serial, item)
            .then(() => {
                if (clearingData && !Helper.hasOnlyDefaultImage(_get(item, "photos", []))) {
                    detachAllImages();
                }
                Toast.success("The gateway has been updated.");

                setInProgress(false);
                setClearingData(false);
                setLocationFacilityId(_get(item, "locationFacilityId"));
                setValidation(validator.valid());
            })
            .catch((err) => {
                if (err.errors) {
                    Object.keys(err.errors).map((key) => {
                        validation[key].isValid = false;
                        validation[key].message = err.errors[key];
                    });

                    setValidation(validation);
                    setInProgress(false);
                }
            });
    };

    const isMote = (item) => {
        return MOTES_LOT_DATA.includes(item.lot_data);
    };

    const onChange = (event) => {
        const isClearingData = _get(event, "name") === "location_id" || clearingData ? +locationFacilityId !== +event.target.facilityId : false;

        let updatedItem = _cloneDeep(item);

        const targetName = event.target.name;
        const targetValue = event.target.value;

        updatedItem[targetName] = targetValue;
        if (targetName === "mode" && targetValue === 1) {
            updatedItem["mode_revert_time"] = 0;
        }

        setItem(updatedItem);
        setClearingData(isClearingData);
        setValidation(validator.validate(updatedItem, targetName));
    };

    const onShowBatteryVoltageModal = (serial) => {
        history.push(Helper.setHashParams({modal: "battery-voltage", serial}));
    };

    const onFlipImage = (imageIndex, flipTurn) => {
        const imageId = _get(item, ["photos", imageIndex, "id"], 0);
        ApiGateways.onFlipImage({id: imageId, flipTurn: flipTurn});
    };

    return (
        <div>
            <HeaderSimple
                breadcrumbs={breadcrumbs}
                globalSearchString={globalSearchString}
                handleChangeGlobalSearch={handleChangeGlobalSearch}
            />
            {loader ? (
                <Loader />
            ) : (
                <div>
                    <div className="subheader">
                        <div className="subheader-title">Edit Gateway {item.serial}</div>
                        <div className="subheader-controls">
                            <div className="subheader-toolbar" />
                        </div>
                    </div>
                    <div className="block">
                        <div className="block-body gateway-edit">
                            <div className="form">
                                <div className="row mb-4">
                                    <div className="col-md-3">
                                        {clearingData && (
                                            <div
                                                className="alert alert-warning"
                                                role="alert"
                                            >
                                                <span>Note that the location and all photos will be removed when you change the facility.</span>
                                            </div>
                                        )}

                                        <ImageToolbar
                                            title={"Add Gateway Photo"}
                                            images={item.photos}
                                            onUploadImages={attachImages}
                                            onDelete={detachImage}
                                            withDropzone={auth.userCan("editEquipment")}
                                            canEdit={!hasOnlyDefaultImage && auth.userCan("editEquipment")}
                                            onFlipTurnImage={onFlipImage}
                                        />
                                    </div>

                                    <div className="col-md-9">
                                        <div className="form-group row">
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-5">
                                                <label>Serial Number</label>
                                                <input
                                                    type="text"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={item.serial}
                                                    placeholder="Serial"
                                                />
                                            </div>
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-5">
                                                <label>Last Seen</label>
                                                <input
                                                    type="text"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={item.last_seen}
                                                    placeholder="Last Seen"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-5">
                                                <label>Address</label>
                                                <input
                                                    type="text"
                                                    readOnly={true}
                                                    className="form-control"
                                                    value={item.post_code}
                                                    placeholder="Address"
                                                />
                                            </div>
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-5">
                                                <label>Name</label>
                                                <input
                                                    type="text"
                                                    className={
                                                        "form-control " + (validation.name.isValid || !validation.name.message ? "" : " is-invalid")
                                                    }
                                                    value={item.name || ""}
                                                    placeholder="Comm Hub name"
                                                    disabled={!auth.userCan("editEquipment")}
                                                    onChange={onChange}
                                                    name={"name"}
                                                />
                                                <ValidationError message={validation.name.message} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-5">
                                                <label>
                                                    <span className="mr-1">Channel</span>
                                                    <InfoTooltip
                                                        size="xxl"
                                                        align="left"
                                                    >
                                                        This drop-down list includes the channels that are not assigned to a gateway in this facility.
                                                    </InfoTooltip>

                                                    {_get(usedChannelsAtFacility, _get(item, "channel_number")) && (
                                                        <React.Fragment>
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target="used-channel-info"
                                                            >
                                                                <ChannelUsageInformation
                                                                    usedChannelsAtFacility={usedChannelsAtFacility}
                                                                    channel={_get(item, "channel_number")}
                                                                />
                                                            </UncontrolledTooltip>

                                                            <span
                                                                className="color-danger"
                                                                id="used-channel-info"
                                                            >
                                                                <i
                                                                    className="fa fa-exclamation-circle"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        </React.Fragment>
                                                    )}
                                                </label>
                                                <SelectWrapper
                                                    placeholder={"Select Channel"}
                                                    value={+_get(item, "channel_number")}
                                                    disabled={!auth.userCan("editEquipment")}
                                                    className={
                                                        "react-select " +
                                                        (validation.channel_number.isValid || !validation.channel_number.message ? "" : " is-invalid")
                                                    }
                                                    name={"channel_number"}
                                                    onChange={(val) => {
                                                        const value = +_get(val, "value");
                                                        _get(item, ["usedChannelsAtFacility", value])
                                                            ? setChannelToChange(value)
                                                            : onChange({
                                                                  target: {
                                                                      name: "channel_number",
                                                                      value: value,
                                                                      label: _get(val, "label"),
                                                                  },
                                                              });
                                                    }}
                                                >
                                                    {_map([...Array(GATEWAY_MAX_CHANNEL_NUMBER + 1).keys()], (channel) => {
                                                        return (
                                                            <option
                                                                key={+channel}
                                                                value={+channel}
                                                            >
                                                                {+channel}
                                                            </option>
                                                        );
                                                    })}
                                                </SelectWrapper>
                                                <ValidationError message={validation.channel_number.message} />
                                            </div>
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-5">
                                                <label>Location</label>
                                                <input
                                                    type="text"
                                                    value={item.location}
                                                    name="location"
                                                    onChange={onChange}
                                                    className="form-control"
                                                    placeholder="Location"
                                                    disabled={!auth.userCan("editEquipment")}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-1" />
                                            <div className="col-lg-5">
                                                <label>Asset Tree Branch</label>
                                                <CollapseLocationSelect
                                                    className={"form-control"}
                                                    selectName={"location_id"}
                                                    value={item.location_id === null ? item.locationIdFromFacility : item.location_id}
                                                    onChange={onChange}
                                                    needMarkFacility={true}
                                                    emptyOptionLabel={"Select Location"}
                                                    allowedLocationIds={_get(user, "allowedLocationIds", [])}
                                                    disabled={!auth.userCan("editEquipment")}
                                                />
                                            </div>
                                            {auth.userCan("manageAdapter") ? (
                                                <>
                                                    <div className="col-lg-1"></div>
                                                    <div className="col-lg-5">
                                                        <ReadingIntervalInput
                                                            key={"wua"}
                                                            type={"wua"}
                                                            settings={item}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <div className={"col-6"} />
                                            )}
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-1" />
                                            <div className="col-lg-5">
                                                <label>
                                                    <span className="mr-1">Operating Mode</span>
                                                    <InfoTooltip
                                                        size="xxl"
                                                        align="left"
                                                    >
                                                        During the initial setup of a gateway installation, you can put it in the installation mode (vs.
                                                        default the normal mode) for troubleshooting purposes. <br /> When the gateway is in the
                                                        installation mode, note the following differences: <br />
                                                        <ul>
                                                            <li>
                                                                Readings are not taken on sensor motes and tethered sensors not assigned to an installation
                                                                point.
                                                            </li>
                                                            <li>
                                                                If the gateway has time available in the current interval, it will immediately try to take
                                                                a reading from the device after a magnet swipe or reboot from a battery insertion.
                                                            </li>
                                                            <li>
                                                                The FFT sample counts are adjusted to smaller values to speed up verifying communication to
                                                                devices.
                                                            </li>
                                                            <li>The interval is set to 5 minutes (vs. 15 minutes in the normal mode).</li>
                                                            <li>Topology information is collected from repeaters more often.</li>
                                                        </ul>
                                                    </InfoTooltip>
                                                </label>
                                                <Select
                                                    className={"react-select"}
                                                    classNamePrefix={"react-select"}
                                                    placeholder={"Select Operating Mode"}
                                                    selectName={"mode"}
                                                    isSearchable={false}
                                                    options={modeOptions}
                                                    isDisabled={!auth.userCan("editEquipment")}
                                                    value={_filter(modeOptions, (n) => {
                                                        return n.value === _get(item, "mode");
                                                    })}
                                                    onChange={(val) => {
                                                        onChange({
                                                            target: {
                                                                name: "mode",
                                                                value: val.value,
                                                                label: val.label,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-5">
                                                <label>
                                                    Operating Mode Revert Time{" "}
                                                    <i>
                                                        {_get(item, "mode") !== 1 && _get(item, "mode_revert_time_indicator") !== null
                                                            ? "(" + _get(item, "mode_revert_time_indicator") + ")"
                                                            : ""}
                                                    </i>
                                                </label>
                                                <Select
                                                    className={
                                                        "react-select " +
                                                        (validation.mode_revert_time.isValid || !validation.mode_revert_time.message ? "" : " is-invalid")
                                                    }
                                                    classNamePrefix={"react-select"}
                                                    placeholder={"Select Operating Mode Revert Time"}
                                                    selectName={"mode_revert_time"}
                                                    isSearchable={false}
                                                    options={modeRevertTimeOptions}
                                                    isDisabled={!auth.userCan("editEquipment") || _get(item, "mode") === 1}
                                                    value={_filter(modeRevertTimeOptions, (n) => {
                                                        return n.value === +_get(item, "mode_revert_time");
                                                    })}
                                                    onChange={(val) => {
                                                        onChange({
                                                            target: {
                                                                name: "mode_revert_time",
                                                                value: val.value,
                                                                label: val.label,
                                                            },
                                                        });
                                                    }}
                                                />
                                                <ValidationError message={validation.mode_revert_time.message} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-lg-1" />
                                            <div className="col-lg-5">
                                                <span className="mr-1">Version</span>
                                                <div>
                                                    <FirmwareVersion
                                                        version={version}
                                                        name={item.name}
                                                        isFirmwareExists={!!item.firmwareExist}
                                                        description={item.firmwareDescription}
                                                        shortDescription={item.firmwareShortDescription}
                                                        type="Gateway"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{marginBottom: 16}} />
                                {auth.userCan("editEquipment") && (
                                    <div className="text-right">
                                        <Button
                                            color="primary"
                                            size="sm"
                                            className="ml-2"
                                            disabled={inProgress}
                                            onClick={handleSubmit}
                                        >
                                            Update
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        {item.nodes && item.nodes.length > 0 ? (
                            item.nodes.map((node) => {
                                if (isMote(node)) {
                                    return (
                                        <div
                                            key={node.serial}
                                            className="col-xl-6"
                                        >
                                            <Mote
                                                mote={node}
                                                onShowBatteryVoltageModal={onShowBatteryVoltageModal}
                                            />
                                        </div>
                                    );
                                }
                                return (
                                    <div
                                        key={node.serial}
                                        className="col-xl-6"
                                    >
                                        <Node
                                            node={node}
                                            onShowBatteryVoltageModal={onShowBatteryVoltageModal}
                                        />
                                    </div>
                                );
                            })
                        ) : (
                            <div className={"col-md-12"}>
                                <div
                                    className="alert alert-info"
                                    role="alert"
                                >
                                    <div className="alert-icon">
                                        <i className="fas fa-exclamation-circle" />
                                    </div>
                                    <div className="alert-text">There are no nodes detected on this gateway.</div>
                                </div>
                            </div>
                        )}
                        {auth.userCan("editEquipment") && (
                            <div className="col-xl-6">
                                <BridgeChannelStats
                                    user={user}
                                    onChange={onChange}
                                    selectedChannel={parseInt(_get(item, "channel_number"))}
                                    gatewayId={_get(item, "gateway_id")}
                                    usedChannelsAtFacility={usedChannelsAtFacility}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
            {searchParams.modal === "battery-voltage" && !!searchParams.serial && (
                <BatteryVoltageModal node={_find((item || {}).nodes, {serial: searchParams.serial})} />
            )}

            {channelToChange !== null && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title=""
                    onConfirm={() => {
                        onChange({
                            target: {
                                name: "channel_number",
                                value: channelToChange,
                            },
                        });
                        setChannelToChange(null);
                    }}
                    onCancel={() => setChannelToChange(null)}
                >
                    <ChannelUsageInformation
                        usedChannelsAtFacility={usedChannelsAtFacility}
                        channel={channelToChange}
                    />
                    Do you want to continue?
                </SweetAlert>
            )}
        </div>
    );
};

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item global-search">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

ViewGateway.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
    auth: PropTypes.object,
};

const ChannelUsageInformation = ({usedChannelsAtFacility, channel}) => {
    return (
        <React.Fragment>
            <p>
                The following gateway(s) are also assigned to this channel and can cause communication problems if the gateways are within physical range
                of each other:
            </p>

            <div>
                {_map(_get(usedChannelsAtFacility, channel), (serial) => {
                    return <p>{serial}</p>;
                })}
            </div>
        </React.Fragment>
    );
};

ChannelUsageInformation.propTypes = {
    usedChannelsAtFacility: PropTypes.array,
    channel: PropTypes.number,
};

export default withGlobalStore(ViewGateway);
