import React, {Component, useRef} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Helper from "../../../helpers/helper";
import {get as _get} from "lodash";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {ResetSortButton} from "../../shared/resetSortButton";
import {DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip} from "reactstrap";
import moment from "moment";
import {usePacketLossChartModal} from "../hooks/usePacketLossChartModal";
import {EQUIPMENT_TYPE_NODE} from "../../../constants/constants";
import SignalWarningTooltip from "../../../components/tooltips/SignalWarningTooltip";
import styles from "./table.module.scss";
import {FirmwareVersion} from "../../../shared/FirmwareVersion/FirmwareVersion";

const headersList = {
    signal_strength: {title: "Signal", sort: true, style: {width: 80}},
    batteryStat: {title: "Battery", sort: true, style: {width: 120}},
    location_info: {title: "Location", sort: true},
    "wwr_installation_point.name": {title: "Installation Point", sort: true},
    "wwr_equipment.name": {title: "Equipment", sort: true},
    serial: {title: "Serial Number", sort: true},
    "gateway.name": {title: "Gateway", sort: true},
    wifi_config_group_name: {title: "Wifi Sensor Mote Group", sort: true},
    version_type: {title: "Type", sort: true},
    "sensor.version": {title: "Version", sort: true},
    tempLastSeen: {title: "Last Temp", sort: true},
    rmsLastSeen: {title: "Last RMS", sort: true},
    fftLastSeen: {title: "Last FFT", sort: true},
    last_seen: {title: "Last Seen", sort: true},
    actions: {title: "Actions", sort: false, additionalClasses: "table-buttons-th", component: ResetSortButton},
};

class Table extends Component {
    render() {
        const {list, sort, onSortChange, onDeleteMote, query, user, auth} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <div className="table-scroll-wrapp tachometers">
                <table className="table table-hover tl-fixed">
                    <thead>
                        <tr>
                            {Object.keys(headersList).map((key) => {
                                if (key === "wifi_config_group_name" && !auth.userCan("manageWiFiGroup")) {
                                    return;
                                }
                                let component = "";
                                let RowComponent = _get(headersList, [key, "component"], false);
                                if (RowComponent) {
                                    component = (
                                        <RowComponent
                                            sort={sort}
                                            resetSort={() => onSortChange("")}
                                        />
                                    );
                                }
                                return listLen && headersList[key].sort ? (
                                    <th
                                        className={headersList[key].additionalClasses || ""}
                                        key={key}
                                        style={headersList[key].style || {}}
                                        onClick={() => onSortChange(key)}
                                    >
                                        {headersList[key].title}
                                        {sort.field === key ? (
                                            <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")} />
                                        ) : (
                                            <i className={"fa fa-sort"} />
                                        )}
                                    </th>
                                ) : (
                                    <th
                                        className={headersList[key].additionalClasses || ""}
                                        key={key}
                                        style={headersList[key].style || {}}
                                    >
                                        {headersList[key].title} {component}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {list.length > 0 ? (
                            list.map((node) => (
                                <MoteRow
                                    query={query}
                                    key={node.serial}
                                    node={node}
                                    onDeleteMote={onDeleteMote}
                                    user={user}
                                    auth={auth}
                                />
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={listLen}
                                    className="text-center text-info"
                                >
                                    {query !== "" ? "No items match your search." : "No sensor motes were found."}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

Table.propTypes = {
    list: PropTypes.array,
    sort: PropTypes.object,
    onSortChange: PropTypes.func,
    onDeleteMote: PropTypes.func,
    query: PropTypes.string,
    user: PropTypes.object,
    auth: PropTypes.object,
};

const WosLabel = () => {
    const targetRef = useRef(null);

    return (
        <>
            <span
                className={styles.wosLabel}
                ref={targetRef}
                id="id"
            >
                WoS
                <i className={"fas fa-hourglass-half"} />
            </span>
            <UncontrolledTooltip
                target={targetRef}
                placement="top"
            >
                Wake-on-Shake is enabled for this sensor, which will determine when vibration readings are collected.
            </UncontrolledTooltip>
        </>
    );
};

const MoteRow = (props) => {
    const {node, query, onDeleteMote, user, auth} = props;

    const [showModal] = usePacketLossChartModal(node.dec_serial, EQUIPMENT_TYPE_NODE);

    const getInstPointLink = (forTable = true) => {
        const {node, query} = props;

        const installationPoint = _get(node, "sensor.installationPoint") || {};

        const text = forTable ? Helper.highlight(installationPoint.name, query) : installationPoint.name;

        return installationPoint && installationPoint.equipment ? (
            <Link
                to={`/chart/${installationPoint.equipment.id}#sensors=${installationPoint.id}`}
                className="link link-primary"
                title="View Installation Point"
                dangerouslySetInnerHTML={{__html: text}}
                style={{margin: 0}}
            />
        ) : (
            <i className="ml-2">Not set</i>
        );
    };

    const getMoteLink = (forTable = true) => {
        const {node, query} = props;

        const text = forTable ? Helper.highlight(node.serial, query) : node.serial;

        return node && node.dec_serial ? (
            <Link
                to={`/network/motes/${node.dec_serial}`}
                className="link link-primary"
                title="View Sensor Mote"
                style={{margin: 0}}
                dangerouslySetInnerHTML={{__html: text}}
            />
        ) : (
            <i className="ml-2">Not set</i>
        );
    };

    const getGatewayLink = (forTable = true) => {
        const {node, query} = props;

        const gateway = (node || {}).gateway || {};
        const gatewayName = gateway ? (gateway.name ? gateway.name + " (" + gateway.serial + ")" : gateway.serial) : "---";

        const text = forTable ? Helper.highlight(gatewayName, query) : gateway.serial;

        return gateway && gateway.serial ? (
            <Link
                to={`/network/gateways/${gateway.serial}`}
                className="link link-primary"
                title="View Gateway"
                dangerouslySetInnerHTML={{__html: text}}
                style={{margin: 0}}
            />
        ) : (
            <>{_get(node, "wifi_config_group_id") ? <i className="ml-2">---</i> : <i className="ml-2">Not set</i>}</>
        );
    };

    const tempLastSeen = _get(node, "tempLastSeen");
    const rmsLastSeen = _get(node, "rmsLastSeen");
    const fftLastSeen = _get(node, "fftLastSeen");
    const equipment = _get(node, "sensor.installationPoint.equipment") || {};
    const version = node?.sensor?.version;

    const isWakeOnShakeActive = !!node?.sensor?.installationPoint?.wosTrigger;

    let batteryColor = Helper.getNodeBatteryColor(node.battery, "mote");
    let signalColor = node.signal_strength;

    if (!_get(node, "nodeLastSeen") || +moment().diff(moment(_get(node, "nodeLastSeen")), "hours") > 24) {
        batteryColor = "secondary";
        signalColor = node.signal_strength + "-gray";
    }

    return (
        <tr>
            <td>
                <span
                    title={Helper.getSignalLabel(node.signal_strength) + (node.lastSeen ? `\nFFT: ${Helper.dateToUserFormat(node.lastSeen, user)}` : "")}
                    className={`signal-lvl position-relative color-${node.signal_strength === "default" ? "" : signalColor}`}
                    style={{marginLeft: 0}}
                    onClick={showModal}
                >
                    {!!(signalColor === node.signal_strength + "-gray" && node.signal_strength !== "default") && (
                        <img
                            title={"Sensor has not been seen in over 24 hours"}
                            className={"yellow-time-icon"}
                            src={"/assets/pic/icon-time.svg"}
                        />
                    )}
                    {node.signal_strength === "default" ? "N/A" : <img src={`/assets/pic/icon-signal-level-${signalColor}.svg`} />}
                </span>
                <SignalWarningTooltip
                    packetLossPercent={_get(node, "packetLossPercent")}
                    tooltipText={"FFT transmission may be impacted by poor connectivity to the device."}
                />
            </td>
            <td>
                <span
                    className={`position-relative color-${batteryColor}`}
                    style={{marginLeft: 0}}
                >
                    {batteryColor === "secondary" && (
                        <img
                            title={"Sensor has not been seen in over 24 hours"}
                            className={"yellow-time-icon"}
                            src={"/assets/pic/icon-time.svg"}
                        />
                    )}
                    {batteryColor === "" ? (
                        "N/A"
                    ) : batteryColor === "warning" ? (
                        <i className="fa fa-battery-half" />
                    ) : (
                        <i className="fa fa-battery-full" />
                    )}
                </span>
            </td>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(node.location || "---", query)}} />
            <td>{getInstPointLink()}</td>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(equipment.name || "---", query)}} />
            <td>{getMoteLink()}</td>
            <td>{getGatewayLink()}</td>
            {auth.userCan("manageWiFiGroup") && (
                <>{_get(node, "wifi_config_group_name") ? <td>{_get(node, "wifi_config_group_name")}</td> : <td>---</td>}</>
            )}
            <td>{_get(node, "version_type", "N/A")}</td>
            <td>
                <FirmwareVersion
                    version={version}
                    name={node?.serial}
                    isFirmwareExists={!!node?.sensor?.firmwareExist}
                    description={node?.sensor?.firmwareDescription}
                    shortDescription={node?.sensor?.firmwareShortDescription}
                    type="Mote"
                />
            </td>
            <td>{tempLastSeen}</td>
            <td className="position-relative">
                {rmsLastSeen}
                {isWakeOnShakeActive && <WosLabel />}
            </td>
            <td className="position-relative">
                <span className="position-relative">{fftLastSeen}</span>
                {isWakeOnShakeActive && <WosLabel />}
            </td>
            <td>{node.last_seen}</td>
            <td>
                <div className="btn-group btn-group-sm">
                    {auth.userCan("editNodes") && (
                        <button
                            className="link link-danger"
                            onClick={() => onDeleteMote(node.serial)}
                        >
                            <i className="fa fa-times" />
                            <span>Delete</span>
                        </button>
                    )}
                    <UncontrolledDropdown className="d-inline-block">
                        <DropdownToggle
                            tag={"button"}
                            className={"link link-primary"}
                            style={{
                                minWidth: "1.5rem",
                                borderRadius: 0,
                                display: "inline-block",
                                lineHeight: "1.6rem",
                                cursor: "pointer",
                                marginLeft: "10px",
                            }}
                        >
                            <i className="fa fa-eye" />
                            <span>Path</span>
                        </DropdownToggle>
                        <DropdownMenu
                            right={true}
                            className={"p-3"}
                        >
                            <div>Installation Point</div>
                            {getInstPointLink(false)}
                            <hr className="mt-2 mb-2" />
                            <div>Sensor Mote</div>
                            {getMoteLink(false)}
                            <hr className="mt-2 mb-2" />
                            <div>Gateway</div>
                            {getGatewayLink(false)}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </td>
        </tr>
    );
};

MoteRow.propTypes = {
    node: PropTypes.object,
    query: PropTypes.string,
    onDeleteMote: PropTypes.func,
    user: PropTypes.object,
    auth: PropTypes.object,
};

const Port = ({port}) => {
    if (!port.sensor) {
        return <i className="badge badge-secondary">Empty Port</i>;
    }

    return <span>{_get(port.sensor, "installationPoint.name", "Unassigned sensor")}</span>;
};

Port.propTypes = {
    port: PropTypes.object,
};

export default withGlobalStore(Table);
