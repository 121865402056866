import {useCurrentAlert, useCurrentAlertActions, useCurrentAlertScrolled} from "../../../../../stores/CurrentAlertStore";
import {getSyncSelector} from "../../store/sync/helpers/getSyncSelector";
import {IMPACT_VUE_CHART_TYPES, useIsImpactVue} from "../../hooks/useChartTypes";
import {useAxes, useAxisSyncStoreActions} from "../../store/sync/axisSyncStore";
import {useSelectDatepickerUnixRangeTimestampSync, useTimestampsSyncStoreActions} from "../../store/sync/timestampsSyncStore";
import {get as _get} from "lodash";
import {useCallback, useEffect} from "react";
import {useChartStateStoreContext} from "../store/hooks/useStoreContext";
import {getCalendarChunkByMonth} from "../../../../../helpers/getCalendarChunkByMonth";

export const useAfterAlertScrollEffect = () => {
    const axes = useAxes();
    const {setTimestampsForFft} = useTimestampsSyncStoreActions();
    const {toggleActiveSeriesForSingleSelect} = useAxisSyncStoreActions();
    const currentAlert = useCurrentAlert();
    const isScrolled = useCurrentAlertScrolled();
    const {setIsScrolled} = useCurrentAlertActions();
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const showAlerts = useChartStateStoreContext((state) => state.settings.showAlerts);
    const {toggleAlerts} = useChartStateStoreContext((state) => state.actions);
    const {setUnixDateRange, setDatepickerUnixRange} = useTimestampsSyncStoreActions();
    const isImpactVue = useIsImpactVue(chartType);
    const localDates = useSelectDatepickerUnixRangeTimestampSync(selectedPoint, isImpactVue);

    const scrollToTarget = useCallback(() => {
        const isFFT = +_get(currentAlert, "alertCondition.fft_alert_type", 0) === 1 || +_get(currentAlert, "alertCondition.fft_alert_type", 0) === 2;
        let installationPoint = +_get(currentAlert, "installationPointId");
        let chartTypeAlert = +_get(currentAlert, "alertCondition.fft_type");

        if (isFFT && +chartType === +chartTypeAlert && +selectedPoint === +installationPoint && !isScrolled) {
            const chartAxes = axes[getSyncSelector(installationPoint, false, false)] || ["1"];
            const alertAxis = currentAlert.axis === "x" ? "1" : currentAlert.axis === "y" ? "2" : currentAlert.axis === "z" ? "3" : currentAlert.axis;
            const [date, time] = currentAlert.timestamp.split(" ");
            const {unixTo, unixFrom} = getCalendarChunkByMonth({
                date: date,
                unixFrom: localDates.unixFrom,
                unixTo: localDates.unixTo,
            });
            if (!chartAxes.includes(alertAxis)) {
                toggleActiveSeriesForSingleSelect(installationPoint, alertAxis);
            }

            if (!showAlerts) {
                toggleAlerts();
            }

            setDatepickerUnixRange({
                isImpactVue,
                installationPointId: selectedPoint,
                unixFrom,
                unixTo,
            });
            setUnixDateRange({
                isImpactVue,
                installationPointId: selectedPoint,
                unixFrom,
                unixTo,
            });

            setTimestampsForFft(
                installationPoint,
                {
                    date,
                    time,
                },
                IMPACT_VUE_CHART_TYPES.includes(+chartTypeAlert)
            );
            setIsScrolled();
        }
    }, [currentAlert]);

    useEffect(() => {
        scrollToTarget();
    }, [scrollToTarget]);
};
