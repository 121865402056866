import React, {useEffect, useRef, useState} from "react";
import {UncontrolledTooltip} from "reactstrap";
import PropTypes from "prop-types";
import "./tooltip.scss";
import {get as _get} from "lodash";

const SIZES = {
    base: "",
    xl: "tooltip-xl",
    xxl: "tooltip-xxl",
};

const ALIGN = {
    center: "",
    left: "tooltip-left",
};

const Tooltip = ({children, text}) => {
    const [isShowTooltip, setIsShowTooltip] = useState(false);
    const tooltipBodyRef = useRef();

    useEffect(() => {
        if (!tooltipBodyRef) {
            return;
        }
        setIsShowTooltip(true);
    }, [tooltipBodyRef.current]);

    return (
        <>
            <span
                className="mr-2"
                ref={tooltipBodyRef}
            >
                {children}
            </span>
            {text && isShowTooltip && (
                <UncontrolledTooltip
                    popperClassName={`${_get(SIZES, "base", "")} ${_get(ALIGN, "center", "")}`}
                    placement="top"
                    target={tooltipBodyRef.current}
                >
                    {text}
                </UncontrolledTooltip>
            )}
        </>
    );
};
Tooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
    text: PropTypes.element,
};

export default Tooltip;
