export const MONTH_LEADING_DATEFORMAT = "m/d/Y H:i:s";
export const DAY_LEADING_DATEFORMAT = "d/m/Y H:i:s";

export const DATEFORMAT = {
    [MONTH_LEADING_DATEFORMAT]: "MM/DD/YY HH:mm:ss",
    [DAY_LEADING_DATEFORMAT]: "DD/MM/YY HH:mm:ss",
};
export const SHORT_DATEFORMAT = {
    [MONTH_LEADING_DATEFORMAT]: "MM/DD HH:mm",
    [DAY_LEADING_DATEFORMAT]: "DD/MM HH:mm",
};

export const SERVER_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const SUPPORT_EMAIL = "support@waites.net";

export const ALARM_EMAIL_FREQUENCY = {
    0: "Receive every alert",
    1: "Once per hour per alert",
    6: "Once per six hours per alert",
    12: "Once per twelve hours per alert",
    24: "Once per day per alert",
    48: "Once every other day per alert",
    168: "Once per week per alert",
    720: "Once per month per alert",
};

export const readingIntervals = [
    {id: 1, name: "Seconds", seconds: 1},
    {id: 2, name: "Minutes", seconds: 60},
    {id: 3, name: "Hours", seconds: 60 * 60},
    {id: 4, name: "Days", seconds: 24 * 60 * 60},
    {id: 5, name: "Weeks", seconds: 7 * 24 * 60 * 60},
];

export const AVG_READINGS_MAX_COUNT = 20;

export const fftAlertTypes = {
    BAND: 1,
    ENVELOPE: 2,
};

export const CHART_VIEW_TYPE = {
    LINEAR: "LINEAR",
    CIRCULAR: "CIRCULAR",
};

export const USER_CHART_LAYOUT_VIEW_TYPES = {
    LIST: "list",
    BLOCKS: "blocks",
};

export const BEARING_COLORS = [
    "#FFA500",
    "#8A2BE2",
    "#DC143C",
    "#1E90FF",
    "#0000FF",
    "#008000",
    "#00CED1",
    "#708090",
    "#9ACD32",
    "#FF1493",
    "#8FBC8F",
    "#b300b2",
];

export const AXES_COLORS = {
    1: "#1E90FF",
    2: "#8A2BE2",
    3: "#556B2F",
};

export const AXIS_LIST = {
    1: "X",
    2: "Y",
    3: "Z",
};

export const AXES_DEFAULT_LABELS = {
    ...AXIS_LIST,
    4: "Custom",
};

export const AXES_COLORS_DARK = {
    1: "#3478c7",
    2: "#8f66b6",
    3: "#658135",
};

export const BEARING_TYPES = ["ftf", "bsf", "bpfo", "bpfi"];

export const CURSORS = {
    normal: "Normal cursor",
    harmonic: "Harmonic",
    sideband: "Sideband",
    bCursor: "Cursor B",
};

export const FREQUENCY_TYPES = {
    HZ: "Hz",
    CPM: "CPM",
    ORDERS: "Orders",
};

export const SPEED_TYPES = {
    FIXED: 1,
    VARIABLE: 2,
    RATIO: 3,
};

export const SPEED_TYPE_LABELS = {
    [SPEED_TYPES.FIXED]: "Fixed",
    [SPEED_TYPES.VARIABLE]: "Variable",
    [SPEED_TYPES.RATIO]: "Ratio",
};

export const IDLE_THRESHOLD_TYPES = {
    ACC: "acceleration",
    VEL: "velocity",
};

export const CHART_TYPE_ACCELERATION_IMPACT_VUE_ID = 15;
export const CHART_TYPE_ACCELERATION_ID = 2;
export const CHART_TYPE_VELOCITY_ID = 3;
export const IDLE_THRESHOLD_CHART_TYPES = {
    [IDLE_THRESHOLD_TYPES.ACC]: [CHART_TYPE_ACCELERATION_ID, CHART_TYPE_ACCELERATION_IMPACT_VUE_ID],
    [IDLE_THRESHOLD_TYPES.VEL]: [CHART_TYPE_VELOCITY_ID],
};

export const EQUIPMENT_SERVICE = {
    FULL: "full",
    SELF: "self",
};

export const CUSTOMER_SYSTEM_VERSION = {
    HYBRID: "hybrid-service",
};

export const DATEPICKER_TYPE = {
    DEFAULT: "default",
    FFT: "fft",
};

export const INST_POINT_COLORS_BY_THEME = {
    "#1E90FF": "#4176FF",
    "#008080": "#A75AE0",
    "#4682B4": "#5F9EA0",
    "#2F4F4F": "#44BD5E",
    "#2E8B57": "#405F00",
    "#778899": "#7C4A00",
    "#5F9EA0": "#B481DD",
    "#8B4513": "#9FD3D0",
    "#0000FF": "#85B623",
    "#6B8E23": "#B2B233",

    "#7B68EE": "#8B2FB6",
    "#006400": "#5991E5",
    "#000080": "#006A0B",
    "#696969": "#976327",
    "#8A2BE2": "#C4B3F6",
    "#556B2F": "#4FC6C6",
    "#483D8B": "#148F19",
    "#C71585": "#A78440",
    "#4B0082": "#666666",
    "#800080": "#3632E8",

    "#808000": "#999999",
    "#20B2AA": "#556B2F",
    "#FF00FF": "#928301",
    "#8FBC8F": "#D9B97C",
    "#9ACD32": "#9E8AED",
    "#BDB76B": "#97B0D6",
    "#66CDAA": "#167474",
    "#FF69B4": "#82E297",
    "#BC8F8F": "#CCCCCC",
    "#DAA520": "#3F3CD0",

    "#DEB887": "#7C6AEF",
    "#FFB6C1": "#618710",
    "#40E0D0": "#149A9A",
    "#7FFFD4": "#A2EFBC",
    "#00FFFF": "#5848B7",
    "#00FA9A": "#2AA936",
    "#00FF00": "#A6CC58",
    "#FFFF00": "#6193C3",
    "#F0E68C": "#B2CC91",
};

export const INST_POINT_COLORS_LIGHT_THEME = Object.keys(INST_POINT_COLORS_BY_THEME);
export const INST_POINT_COLORS_DARK_THEME = Object.values(INST_POINT_COLORS_BY_THEME);

export const SENSOR_TYPES = {
    V1: 86,
    V1b: 6,
    V2: 1,
    M001: 2,
    M002: 3,
    M003: 4,
    M004: 5,
    S00102: 6,
    S003: 7,
    TACH: 9,
    WIFI: 17,
};

export const AI_COLOR = {
    Standard: "#e3bb31",
    High: "#d6812e",
    Critical: "#b44646",
};

export const ALERT_LEVELS_LIGHTER_COLORS = {
    Caution: "#fcf8ea",
    Warning: "#fdecec",
};

export const PROFILE_TYPES = [
    {
        image: "/assets/img/profile/fan.svg",
        hover_image: "/assets/img/profile/fan-white.svg",
    },
    {
        image: "/assets/img/profile/fan.svg",
        hover_image: "/assets/img/profile/fan-white.svg",
    },
    {
        image: "/assets/img/profile/fan.svg",
        hover_image: "/assets/img/profile/fan-white.svg",
    },
    {
        image: "/assets/img/profile/fan.svg",
        hover_image: "/assets/img/profile/fan-white.svg",
    },
    {
        image: "/assets/img/profile/fan.svg",
        hover_image: "/assets/img/profile/fan-white.svg",
    },
    {
        image: "/assets/img/profile/fan.svg",
        hover_image: "/assets/img/profile/fan-white.svg",
    },
    {
        image: "/assets/img/profile/fan.svg",
        hover_image: "/assets/img/profile/fan-white.svg",
    },
    {
        image: "/assets/img/profile/fan.svg",
        hover_image: "/assets/img/profile/fan-white.svg",
    },
    {
        image: "/assets/img/profile/fan.svg",
        hover_image: "/assets/img/profile/fan-white.svg",
    },
    {
        image: "/assets/img/profile/fan.svg",
        hover_image: "/assets/img/profile/fan-white.svg",
    },
    {
        image: "/assets/img/profile/fan.svg",
        hover_image: "/assets/img/profile/fan-white.svg",
    },
    {
        image: "/assets/img/profile/fan.svg",
        hover_image: "/assets/img/profile/fan-white.svg",
    },
];

export const RPM_FROM = {
    SENSOR: "Sensor",
    EQUIPMENT: "Equipment",
    READING: "Reading",
    TACHOMETER: "Nearest Tachometer",
};

export const VERSION_TYPE_NAME = {
    86: "V1 tethered sensor",
    6: "V1b tethered sensor",
    1: "V2 tethered sensor",
    7: "V3 tethered sensor",
    2: "SM-1 mote",
    3: "SM-2 mote",
    4: "SM-3 mote",
    5: "SM-4 mote",
    10: "V2B tethered sensor",
    12: "SM-1a mote",
    13: "SM-2a mote",
    15: "SM-4a mote",
    17: "WIFI mote",
    23: "SM6-1150 mote",
    24: "SM6-0864 mote",
    25: "SM6-2300 mote",
    26: "SM2-0864 mote",
};

export const CUSTOMER_STORAGE_AZURE = "azure";

export const EQUIPMENT_TYPE_GATEWAY = "gateway";
export const EQUIPMENT_TYPE_NODE = "node";
export const EQUIPMENT_TYPE_REPEATER = "repeater";
export const EQUIPMENT_TYPE_SENSOR_MOTE_SM1_SM2_SM6 = "mote_sm1_sm2_sm6";
export const EQUIPMENT_TYPE_SENSOR_MOTE_SM7 = "mote_sm7";
export const EQUIPMENT_TYPE_TACHOMETER = "tachometer";
export const EQUIPMENT_TYPE_TETHERED_SENSOR = "sensor";
export const EQUIPMENT_TYPE_UNIVERSAL_ADAPTER = "universal_adapter";

export const CHART_TYPE_FFT_ACCELERATION_IMPACT_VUE_ID = 19;
export const CHART_TYPE_FFT_VELOCITY_IMPACT_VUE_ID = 20;

export const CHART_TYPE_TWF_ACCELERATION_IMPACT_VUE_ID = 21;
export const CHART_TYPE_TWF_VELOCITY_IMPACT_VUE_ID = 22;

export const FFT_CHART_TYPES = [8, 9, 19, 20];
export const IMPACT_VUE_TIMESTAMPS = "fftTimestampsImpactVue";
export const REGULAR_TIMESTAMPS = "fftTimestamps";

const MOTE_LOT_DATA = "MOTE";
const MG24_MOTE_LOT_DATA = "MG24_MOTE";

export const MOTES_LOT_DATA = [MOTE_LOT_DATA, MG24_MOTE_LOT_DATA];

export const GATEWAY_MAX_CHANNEL_NUMBER = 14;

export const SELF_SERVICE = "self-service";
export const HYBRID_SERVICE = "hybrid-service";

export const ON_DEMAND_REASON = {
    UNKNOWN: 0,
    LOCAL_TRIGGER: 1,
    CLOUD: 2,
    BUTTON: 3,
    LOCAL_ALARM: 4,
    IDLE_THRESHOLD: 5,
};

export const ALLOWABLE_PACKET_LOSS_PERCENTAGE = 35;
export const ALLOWABLE_SIGNAL_STRENGTH = -80;
export const INACTIVE_CUSTOMER_STATUS_TYPE = 3;
export const HONDA_CUSTOMER_ID = 264;
export const SRG_GLOBAL_CUSTOMER_ID = 307;
export const CUSTOMERS_WITH_ACCESS_TO_DATA_FOR_2_YEARS = [HONDA_CUSTOMER_ID, SRG_GLOBAL_CUSTOMER_ID];
export const SIGNAL_LABEL_SUCCESS = "success";
export const SIGNAL_LABEL_WARNING = "warning";
export const SIGNAL_LABEL_DANGER = "danger";

export const ADAPTER_VALUE_TYPE = {
    V05: 1,
    V010: 2,
    MA420: 3,
    RTD: 4,
};
