import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Helper from "../../../helpers/helper";
import {get as _get} from "lodash";
import {withGlobalStore} from "../../../stores/GlobalStore";
import moment from "moment";
import {ResetSortButton} from "../../shared/resetSortButton";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {usePacketLossChartModal} from "../hooks/usePacketLossChartModal";
import {EQUIPMENT_TYPE_NODE} from "../../../constants/constants";
import SignalWarningTooltip from "../../../components/tooltips/SignalWarningTooltip";
import {FirmwareVersion} from "../../../shared/FirmwareVersion/FirmwareVersion";

const headersList = {
    signal_strength: {title: "Signal", sort: true, style: {width: 80}},
    batteryStat: {title: "Battery", sort: true, style: {width: 120}},
    location_info: {title: "Location", sort: true},
    serial: {title: "Serial Number", sort: true},
    radio_address: {title: "Radio Address", sort: true},
    "gateway.name": {title: "Gateway", sort: true},
    sensors: {title: "Sensors", sort: false},
    last_seen: {title: "Last Seen", sort: true},
    code_version: {title: "Version", sort: true},
    actions: {title: "Actions", sort: false, additionalClasses: "table-buttons-th", component: ResetSortButton},
};

class Table extends Component {
    render() {
        const {list, sort, onSortChange, onShowBatteryVoltageModal, onDeleteNode, query, user, auth} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <div className="table-scroll-wrapp tachometers">
                <table className="table table-hover tl-fixed">
                    <thead>
                        <tr>
                            {Object.keys(headersList).map((key) => {
                                let component = "";
                                const RowComponent = _get(headersList, [key, "component"], false);
                                if (RowComponent) {
                                    component = (
                                        <RowComponent
                                            sort={sort}
                                            resetSort={() => onSortChange("")}
                                        />
                                    );
                                }
                                return listLen && headersList[key].sort ? (
                                    <th
                                        className={headersList[key].additionalClasses || ""}
                                        key={key}
                                        style={headersList[key].style || {}}
                                        onClick={() => onSortChange(key)}
                                    >
                                        {headersList[key].title}
                                        {sort.field === key ? (
                                            <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")} />
                                        ) : (
                                            <i className={"fa fa-sort"} />
                                        )}
                                    </th>
                                ) : (
                                    <th
                                        className={headersList[key].additionalClasses || ""}
                                        key={key}
                                        style={headersList[key].style || {}}
                                    >
                                        {headersList[key].title} {component}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {list.length > 0 ? (
                            list.map((node) => (
                                <NodeRow
                                    query={query}
                                    key={node.serial}
                                    node={node}
                                    onShowBatteryVoltageModal={onShowBatteryVoltageModal}
                                    onDeleteNode={onDeleteNode}
                                    user={user}
                                    auth={auth}
                                />
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={listLen}
                                    className="text-center text-info"
                                >
                                    {query !== "" ? "No items match your search." : "No nodes were found."}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

Table.propTypes = {
    list: PropTypes.array,
    sort: PropTypes.object,
    onShowBatteryVoltageModal: PropTypes.func,
    onSortChange: PropTypes.func,
    onDeleteNode: PropTypes.func,
    query: PropTypes.string,
    user: PropTypes.object,
    auth: PropTypes.object,
};

const NodeRow = (props) => {
    const {node, query, onDeleteNode, user, auth} = props;
    const version = node?.code_version;

    const [showModal] = usePacketLossChartModal(node.serial, EQUIPMENT_TYPE_NODE);

    const getNodeLink = (forTable = true) => {
        const {node, query} = props;

        const text = forTable ? Helper.highlight(node.serial, query) : node.serial;

        return node && node.serial ? (
            <Link
                to={`/network/nodes/${node.serial}`}
                className="link link-primary"
                title="View Node"
                style={{margin: 0}}
                dangerouslySetInnerHTML={{__html: text}}
            />
        ) : (
            <i className="ml-2">Not set</i>
        );
    };

    const getGatewayLink = (forTable = true) => {
        const {node, query} = props;

        const gateway = (node || {}).gateway || {};
        const gatewayName = gateway ? (gateway.name ? gateway.name + " (" + gateway.serial + ")" : gateway.serial) : "---";

        const text = forTable ? Helper.highlight(gatewayName, query) : gateway.serial;

        return gateway && gateway.serial ? (
            <Link
                to={`/network/gateways/${gateway.serial}`}
                className="link link-primary"
                title="View Gateway"
                dangerouslySetInnerHTML={{__html: text}}
                style={{margin: 0}}
            />
        ) : (
            <i className="ml-2">Not set</i>
        );
    };

    const batteryPowerType = node.power_type;
    const batterySetting = Helper.getBatterySetting(batteryPowerType, node.battery) || {};
    const ports = node.ports.position ? [{position: node.ports.position, sensor: node.ports[0]}] : _get(node, "ports", []);

    let batteryColor = batterySetting.color;
    let signalColor = node.signal_strength;

    if (!_get(node, "nodeLastSeen") || +moment().diff(moment(_get(node, "nodeLastSeen")), "hours") > 24) {
        batteryColor = "secondary";
        signalColor = node.signal_strength + "-gray";
    }

    return (
        <tr>
            <td>
                <span
                    title={Helper.getSignalLabel(node.signal_strength) + (node.lastSeen ? `\nFFT: ${Helper.dateToUserFormat(node.lastSeen, user)}` : "")}
                    className={`signal-lvl position-relative color-${node.signal_strength === "default" ? "" : signalColor}`}
                    style={{marginLeft: 0}}
                    onClick={showModal}
                >
                    {!!(signalColor === node.signal_strength + "-gray" && node.signal_strength !== "default") && (
                        <img
                            title={"Sensor has not been seen in over 24 hours"}
                            className={"yellow-time-icon"}
                            src={"/assets/pic/icon-time.svg"}
                        />
                    )}
                    {node.signal_strength === "default" ? "N/A" : <img src={`/assets/pic/icon-signal-level-${signalColor}.svg`} />}
                </span>
                <SignalWarningTooltip
                    packetLossPercent={_get(node, "packetLossPercent")}
                    tooltipText={"FFT transmission may be impacted by poor connectivity to the device."}
                />
            </td>
            <td>
                <span
                    className={`position-relative color-${batteryColor}`}
                    style={{marginLeft: 0}}
                >
                    {batteryColor === "secondary" && (
                        <img
                            title={"Sensor has not been seen in over 24 hours"}
                            className={"yellow-time-icon"}
                            src={"/assets/pic/icon-time.svg"}
                        />
                    )}
                    {batteryColor === "" ? (
                        "N/A"
                    ) : batteryColor === "warning" ? (
                        <i className="fa fa-battery-half" />
                    ) : (
                        <i className="fa fa-battery-full" />
                    )}
                </span>
            </td>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(node.location || "---", query)}} />
            <td>{getNodeLink()}</td>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(node.radio_address, query)}} />
            <td>{getGatewayLink()}</td>
            <td>
                {ports.map((port) => {
                    if (_get(port, "tachometer")) {
                        return (
                            <TachometerPort
                                key={"" + node.serial + port.position}
                                port={port}
                            />
                        );
                    } else if (_get(port, "sensor.installationPoint")) {
                        return (
                            <Link
                                key={port.sensor.sensor_id}
                                to={`/chart/${port.sensor.installationPoint.equipment_id}#sensors=${port.sensor.installationPoint.id}`}
                                className={`badge _badge badge-${
                                    moment.utc(port.sensor.last_seen).isAfter(moment().subtract(4, "hours").utc()) ? "success" : "danger"
                                }`}
                                style={{minWidth: "1.5rem", borderRadius: 0, display: "inline-block", lineHeight: "1.6rem"}}
                                title={port.sensor.installationPoint.name || ""}
                            >
                                {port.sensor.position}
                            </Link>
                        );
                    } else {
                        return (
                            <span
                                key={"" + node.serial + port.position}
                                className="badge _badge badge-secondary"
                                style={{minWidth: "1.5rem", borderRadius: 0, display: "inline-block", lineHeight: "1.6rem"}}
                            >
                                {port.position}
                            </span>
                        );
                    }
                })}
            </td>
            <td>{node.last_seen}</td>
            <td>
                <FirmwareVersion
                    version={version}
                    name={node?.serial}
                    isFirmwareExists={!!node?.firmwareExist}
                    description={node?.firmwareDescription}
                    shortDescription={node?.firmwareShortDescription}
                    type="Node"
                />
            </td>
            <td>
                <div className="btn-group btn-group-sm">
                    {auth.userCan("editNodes") && (
                        <button
                            className="link link-danger"
                            onClick={() => onDeleteNode(node.serial)}
                        >
                            <i className="fa fa-times" />
                            <span>Delete</span>
                        </button>
                    )}
                    <UncontrolledDropdown className="d-inline-block">
                        <DropdownToggle
                            tag={"button"}
                            className={"link link-primary"}
                            style={{
                                minWidth: "1.5rem",
                                borderRadius: 0,
                                display: "inline-block",
                                lineHeight: "1.6rem",
                                cursor: "pointer",
                                marginLeft: "10px",
                            }}
                        >
                            <i className="fa fa-eye" />
                            <span>Path</span>
                        </DropdownToggle>
                        <DropdownMenu
                            right={true}
                            className={"p-3"}
                        >
                            <div>Node</div>
                            {getNodeLink(false)}
                            <hr className="mt-2 mb-2" />
                            <div>Gateway</div>
                            {getGatewayLink(false)}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </td>
        </tr>
    );
};

NodeRow.propTypes = {
    node: PropTypes.object,
    query: PropTypes.string,
    onShowBatteryVoltageModal: PropTypes.func,
    onDeleteNode: PropTypes.func,
    user: PropTypes.object,
    auth: PropTypes.object,
};

const Port = ({port}) => {
    if (!port.sensor) {
        return <i className="badge badge-secondary">Empty Port</i>;
    }

    return <span>{_get(port.sensor, "installationPoint.name", "Unassigned sensor")}</span>;
};

Port.propTypes = {
    port: PropTypes.object,
};

const TachometerPort = ({port}) => {
    const tachometerPoints = _get(port, "tachometer.tachometerPoints");

    if (!tachometerPoints.length) {
        return (
            <span
                className="badge _badge badge-primary"
                style={{minWidth: "1.5rem", borderRadius: 0, display: "inline-block", lineHeight: "1.6rem"}}
                title="No Equipment"
            >
                {port.position}
            </span>
        );
    } else if (tachometerPoints.length === 1) {
        return (
            <Link
                to={`/chart/${tachometerPoints[0].equipment_id}`}
                className="badge _badge badge-primary"
                style={{minWidth: "1.5rem", borderRadius: 0, display: "inline-block", lineHeight: "1.6rem"}}
                title={tachometerPoints[0].equipment.name}
            >
                {port.position}
            </Link>
        );
    } else {
        return (
            <UncontrolledDropdown className="d-inline-block">
                <DropdownToggle
                    tag={"span"}
                    className="badge _badge badge-primary"
                    style={{minWidth: "1.5rem", borderRadius: 0, display: "inline-block", lineHeight: "1.6rem", cursor: "pointer"}}
                    title={"Select Equipment"}
                >
                    {port.position}
                </DropdownToggle>
                <DropdownMenu>
                    {tachometerPoints.map((tachometerPoint, index) => (
                        <DropdownItem
                            key={index}
                            tag={Link}
                            to={`/chart/${tachometerPoint.equipment_id}`}
                        >
                            {tachometerPoint.equipment.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
};

TachometerPort.propTypes = {
    port: PropTypes.object,
};

export default withGlobalStore(Table);
