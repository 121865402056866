import React from "react";
import PropTypes from "prop-types";
import {ALLOWABLE_PACKET_LOSS_PERCENTAGE, ALLOWABLE_SIGNAL_STRENGTH} from "../../constants/constants";
import InfoTooltip from "../../shared/infoTooltip/infoTooltip";

const SignalWarningTooltip = ({packetLossPercent, tooltipText, signalStrength = null}) => {
    if (packetLossPercent > ALLOWABLE_PACKET_LOSS_PERCENTAGE || signalStrength < ALLOWABLE_SIGNAL_STRENGTH) {
        return (
            <span className={"ml-2"}>
                <InfoTooltip variant={"warning"}>{tooltipText}</InfoTooltip>
            </span>
        );
    }

    return null;
};

SignalWarningTooltip.propTypes = {
    packetLossPercent: PropTypes.number,
    signalStrength: PropTypes.number,
    tooltipText: PropTypes.string,
};

export default SignalWarningTooltip;
