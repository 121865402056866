import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiEquipment from "../../../api/equipment";
import Toast from "../../../pages/shared/toast";
import {API_CONSTANTS} from "../../../api/constants/constants";
import {useParams} from "react-router";

const assetUpdateFn = ({id, data}) => ApiEquipment.update(id, data);

export const useEquipmentUpdateMutation = (onSuccess = () => {}) => {
    const params = useParams();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: assetUpdateFn,
        onSuccess: (res) => {
            if (res.status !== "error") {
                queryClient.invalidateQueries({queryKey: [API_CONSTANTS.GET_EQUIPMENT, +params.equipmentId]});
                Toast.success("The equipment has been updated.");
                onSuccess();
            }
        },
    });
};
