import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {get as _get, each, indexOf, isEmpty, isNull, isUndefined, includes as _includes} from "lodash";
import Select from "react-select";
import {withLocationSelectStore} from "../../stores/LocationSelectStore";
import cookies from "react-cookies";
import {withGlobalStore} from "../../stores/GlobalStore";
import {getLocationId, setLocationId, getLocationName} from "../../helpers/locations-list";

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        if (ref.current !== value) {
            ref.current = value;
        }
    }, [value]);
    return ref.current;
}

const CollapseLocationSelect = ({...props}) => {
    const [options, setOptions] = useState([]);
    const [collapse, setCollapse] = useState([]);
    const [changeSelect, setChangeSelect] = useState({});
    const [newProps, setNewProps] = useState({});

    const {locationList} = props;

    useEffect(() => {
        let tmpNewProps = {};
        if (!isUndefined(props.disabled)) {
            tmpNewProps.isDisabled = props.disabled;
        }
        if (!isNull(_get(props, ["maxMenuHeight"], null))) {
            tmpNewProps.maxMenuHeight = _get(props, ["maxMenuHeight"], 300);
        }
        tmpNewProps.styles = {
            ...tmpNewProps.styles,
            control: (provided) => ({
                ...provided,
                width: _get(props.style, ["width"], 150),
            }),
            container: (styles) => ({
                ...styles,
                marginLeft: _get(props.style, ["marginLeft"], 0),
                display: _get(props.style, ["display"], "block"),
            }),
        };
        if (newProps !== tmpNewProps) {
            setNewProps(tmpNewProps);
        }
    }, [props.style, props.className, props.disabled]);

    useLayoutEffect(() => {
        if (props.setLocationName) {
            props.setLocationName(getLocationName());
        }
    }, []);

    let prevValue = usePrevious(props.value);

    const handleOptions = (refresh = false, refreshCollapse = true) => {
        if (isEmpty(options) || refresh) {
            let optList = newOptions(locationList);
            handleOptCollapse();
            if (refreshCollapse) {
                setCollapse(_get(optList, "collapse"));
            }
        }
    };

    const getFavoriteData = () => {
        let cid = _get(props, "auth.customer.customer_id", null);
        let uid = _get(props, "user.id", null);
        let favoriteArr = [];
        let cookieName = null;

        if (!isNull(cid) && !isNull(uid)) {
            cookieName = "favorite-asset-tree-" + cid + "-" + uid;
            if (!isUndefined(cookies.load(cookieName))) {
                favoriteArr = cookies.load(cookieName).split(",");
            }
        }

        return {
            cid: cid,
            uid: uid,
            favoriteArr: favoriteArr,
            cookieName: cookieName,
        };
    };

    const handleSelectedOption = () => {
        let tmpSelectedOpt = false;
        if (props.value) {
            tmpSelectedOpt = findLocation(options, props.value);
        }

        setChangeSelect(tmpSelectedOpt || _get(options, 0));
    };
    const hideCollapsedItems = (storage, option) => {
        storage = {collapse: storage.collapse.filter((id) => id !== option.id)};
        each(option.children, (item) => {
            storage = hideCollapsedItems(storage, item);
        });
        return storage;
    };
    const handleChildrenCollapse = (option, storage = {}, parent = null) => {
        if (isEmpty(storage)) {
            storage.collapse = collapse;
        }
        if (option.children.length > 0) {
            if (indexOf(storage.collapse, option.id) === -1) {
                storage = {collapse: [...storage.collapse, option.id]};
            } else {
                storage = hideCollapsedItems(storage, option);
            }
            if (!isNull(parent)) {
                if (indexOf(storage.collapse, parent) === -1) {
                    storage = {collapse: storage.collapse.filter((id) => id !== option.id)};
                }
            }
        }
        return storage;
    };

    const searchParentId = (children, targetId) => {
        const path = [];
        (function search(children) {
            return children?.some((child) => {
                path.push(child.id);

                if (child.id === targetId || search(child.children)) {
                    return true;
                }

                path.pop();
            });
        })(children);
        return path;
    };

    const onChangeFavoriteData = (id) => {
        let {favoriteArr, cookieName} = getFavoriteData();

        const expires = new Date();
        expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 356);

        let index = indexOf(favoriteArr, id.toString());

        if (index === -1) {
            favoriteArr.push(id.toString());
        } else {
            favoriteArr = favoriteArr.filter((i) => i !== id.toString());
        }

        if (!isNull(cookieName)) {
            if (isEmpty(favoriteArr)) {
                cookies.remove(cookieName, {path: "/", domain: process.env.SSO_COOKIE_DOMAIN});
                setLocationId(0);
            } else {
                cookies.save(cookieName, favoriteArr.join(","), {
                    expires: expires,
                    maxAge: 1000 * 60 * 60 * 24 * 356,
                    path: "/",
                    domain: process.env.SSO_COOKIE_DOMAIN,
                });
            }
        }
        handleOptions(true, false);
    };

    const newOptions = (list, storage = {list: [], collapse: []}, parent = null) => {
        let {favoriteArr} = getFavoriteData();
        if (isEmpty(storage.list)) {
            storage.list.push({
                id: "",
                value: "",
                name: props.emptyOptionLabel || "---",
                parent: null,
                children: [],
                level: 1,
                isFavorite: false,
                isDefault: true,
            });
            if (!isEmpty(favoriteArr) && props.withFavorite) {
                storage.list.push({
                    id: -2,
                    value: -2,
                    name: "Favorite",
                    parent: null,
                    children: [],
                    level: 1,
                    isFavorite: false,
                    isDefault: true,
                });
            }
        }
        (list || []).map((item) => {
            storage.list.push({
                id: item.id,
                value: item.id,
                isDisabled: props.allowedLocationIds && !_includes(props.allowedLocationIds, +item.id),
                name: "-".repeat(item.level - 1) + " " + item.name,
                parent: parent,
                children: item.children || [],
                level: item.level,
                isFavorite: indexOf(favoriteArr, item.id.toString()) > -1,
                facilityId: _get(item, "facility_id"),
                facilityType: item.facility?.system_type,
            });
            if (+getLocationId() === +item.id || +props.value === +item.id) {
                storage.collapse.push(...searchParentId(locationList, item.id));
            }
            if ((item.children || []).length) {
                newOptions(item.children, storage, item.id);
            }
        });
        return storage;
    };

    const formatOptionLabel = (data, type) => {
        if (type.context === "value") {
            return <div>{data.name}</div>;
        }
        if (type.context === "menu") {
            return (
                <React.Fragment>
                    <div style={{display: "flex"}}>
                        {data.children && !isEmpty(data.children) && (
                            <span
                                style={{
                                    marginLeft: "-5px",
                                    position: "absolute",
                                    cursor: "pointer",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setCollapse(_get(handleChildrenCollapse(data), "collapse"));
                                }}
                            >
                                <i className={"la la-" + (indexOf(collapse, data.id) === -1 ? "plus" : "minus")}></i>
                            </span>
                        )}
                        <div
                            className={"col-sm-10 ml-2 pl-" + data.level}
                            id={"opt-val-" + data.id}
                            style={{overflow: "hidden"}}
                        >
                            {data.name}
                        </div>
                        {!data.isDefault && props.withFavorite && (
                            <div
                                className={"col-sm-1"}
                                id={"opt-val-" + data.id}
                            >
                                <i
                                    className={(!data.isFavorite ? "font-weight-light" : "") + " fas fa-star float-end collapse-favorite"}
                                    title={data.isFavorite ? "Remove from Favorite" : "Add to Favorite"}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        onChangeFavoriteData(data.id);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </React.Fragment>
            );
        }
    };

    const getFacilityId = (locationId) => {
        if (!props.needMarkFacility) {
            return null;
        }

        return findFacilityId(locationList, locationId);
    };

    const findFacilityId = (list, locationId) => {
        let result = null;

        each(list || [], (item) => {
            if (+_get(item, "id") === +locationId) {
                result = +_get(item, "facility_id");
                return false;
            }
            if (_get(item, "children", []).length) {
                const findId = findFacilityId(_get(item, "children", []), locationId);
                if (findId) {
                    result = findId;
                    return false;
                }
            }
        });

        return result;
    };

    const findLocation = (list, locationId) => {
        let result = null;

        each(list || [], (item) => {
            if (+_get(item, "id") === +locationId) {
                result = {
                    id: item.id,
                    value: item.id,
                    name: item.name,
                    level: item.level,
                };
                return false;
            }
            if (_get(item, "children", []).length) {
                const findItem = findLocation(_get(item, "children", []), locationId);
                if (findItem) {
                    result = findItem;
                    return false;
                }
            }
        });

        return result;
    };

    const selectChange = (val) => {
        const facilityId = getFacilityId(_get(val, "value"));
        if (props.checkStoreValue) {
            setLocationId(val.value);
        }

        let locationName = "";
        if (val.value !== "") {
            locationName = val.name;
        }
        props.setLocationName && props.setLocationName(locationName);
        props.onChange(
            {
                value: val.value,
                label: val.label,
                name: _get(props, ["selectName"]),
                target: {
                    name: _get(props, ["selectName"]),
                    value: val.value,
                    label: val.name,
                    facilityId: facilityId,
                    facilityType: val.facilityType,
                },
            },
            facilityId
        );
    };

    const handleOptCollapse = () => {
        const {facilityForLocations} = props;
        setOptions(
            _get(newOptions(locationList), "list").filter((data) => {
                if (facilityForLocations && _get(data, "facilityId") && _get(data, "facilityId") !== facilityForLocations) {
                    return false;
                }

                if ((!isNull(data.parent) && indexOf(collapse, data.parent) !== -1) || isNull(data.parent)) {
                    return data;
                }

                return false;
            })
        );
    };

    useEffect(() => {
        handleOptions(true);
    }, [locationList]);

    useEffect(() => {
        handleSelectedOption();
        if (+props.value !== +prevValue && props.checkStoreValue) {
            setLocationId(props.value);
            handleSelectedOption();
            if (props.setLocationName) {
                props.setLocationName(getLocationName());
            }
        }
    }, [props.value, options]);

    useEffect(() => {
        handleOptCollapse();
    }, [collapse]);

    return (
        <React.Fragment>
            <Select
                {...newProps}
                options={options}
                value={changeSelect}
                onChange={selectChange}
                formatOptionLabel={formatOptionLabel}
                isSearchable={false}
                className={"react-select" + " " + props.addClassName}
                classNamePrefix={"react-select"}
                menuPortalTarget={document.body}
                closeMenuOnScroll={(e) => e.target === document && true}
            />
        </React.Fragment>
    );
};

CollapseLocationSelect.propTypes = {
    style: PropTypes.object,
    onChange: PropTypes.func,
    setLocationName: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectName: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    emptyOptionLabel: PropTypes.string,
    needMarkFacility: PropTypes.bool,
    allowedLocationIds: PropTypes.array,
    checkStoreValue: PropTypes.bool,
    withFavorite: PropTypes.bool,
    addClassName: PropTypes.string,

    // LocationSelectStore
    setLocationId: PropTypes.func,
    getLocationId: PropTypes.func,
    locationList: PropTypes.array,
    locationTree: PropTypes.array,
    getLocationName: PropTypes.func,
    facilityForLocations: PropTypes.number,
};

export default withLocationSelectStore(withGlobalStore(CollapseLocationSelect));
