import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";

import styles from "./styles.module.scss";
import {useIsDarkTheme} from "../../../../hooks/helpers/theme";

const ArrowIcon = () => (
    <svg
        width="8"
        height="10"
        viewBox="0 0 8 10"
        className="breadcrumbs__arrow"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.08341 0L0.916748 1.16667L4.75008 5L0.916748 8.83333L2.08341 10L7.08341 5L2.08341 0Z"
            fill="#B1B1B1"
        />
    </svg>
);

const ButtonNavigation = ({text, onClick, disabled, variant}) => {
    const isDarkTheme = useIsDarkTheme();
    return (
        <button
            disabled={disabled}
            className={cx(styles.equipmentNavigation, {
                [styles.equipmentNavigationDark]: isDarkTheme === "true",
                [styles.rotate]: variant === "rotate",
            })}
            onClick={onClick}
        >
            {text}
            <ArrowIcon />
        </button>
    );
};

ButtonNavigation.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
};

export default ButtonNavigation;
