import {useQuery} from "@tanstack/react-query";
import {API_CONSTANTS} from "../../../api/constants/constants";
import Api from "../../../api/api";

const getAdjacentAssetKey = (filters) => [API_CONSTANTS.GET_ADJACENT_ASSET_KEY, filters.equipmentId];
const getAdjacentAssetFn = (filters) =>
    Api.get(`ssd/asset/${filters.equipmentId}/adjacent?autoSorting=${filters.sort}`, {host: `${process.env.API_V2_URL}`});

export const useGetAdjacentAssetQuery = (filters) => {
    return useQuery({
        queryFn: () => getAdjacentAssetFn(filters),
        queryKey: getAdjacentAssetKey(filters),
        select: (res) => res.data,
        enabled: !!filters.equipmentId,
    });
};
