import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Helper from "../../../helpers/helper";
import {get as _get} from "lodash";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {ResetSortButton} from "../../shared/resetSortButton";
import {DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {usePacketLossChartModal} from "../hooks/usePacketLossChartModal";
import {
    EQUIPMENT_TYPE_REPEATER,
    SIGNAL_LABEL_DANGER,
    SIGNAL_LABEL_SUCCESS,
    SIGNAL_LABEL_WARNING
} from "../../../constants/constants";
import SignalWarningTooltip from "../../../components/tooltips/SignalWarningTooltip";
import {FirmwareVersion} from "../../../shared/FirmwareVersion/FirmwareVersion";

const headersList = {
    "signal": {title: "Signal", sort: true},
    "location_info": {title: "Location", sort: true},
    "serial": {title: "Serial Number", sort: true},
    "radio_address": {title: "Radio Address", sort: true},
    "gateway.name": {title: "Gateway", sort: true},
    "last_seen": {title: "Last Seen", sort: true},
    "radio_version": {title: "Version", sort: true},
    "actions": {title: "Actions", sort: false,  additionalClasses: "table-buttons-th", component: ResetSortButton}
};

class Table extends Component
{
    render() {
        const {list, sort, onSortChange, onDeleteRouter, query, auth} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <div className="table-scroll-wrapp tachometers">
                <table className="table table-hover tl-fixed">
                    <thead>
                        <tr>
                            {Object.keys(headersList).map((key) => {
                                let component = "";
                                const RowComponent = _get(headersList, [key, "component"], false);
                                if (RowComponent) {
                                    component = <RowComponent sort={sort} resetSort={() => onSortChange("")}/>;
                                }
                                return (
                                    listLen && headersList[key].sort
                                        ?
                                        <th className={headersList[key].additionalClasses || ""} key={key} style={headersList[key].style || {}} onClick={() => onSortChange(key)}>
                                            {headersList[key].title}
                                            {sort.field === key ? <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")}/> : <i className={"fa fa-sort"} />}
                                        </th>
                                        :
                                        <th className={headersList[key].additionalClasses || ""} key={key} style={headersList[key].style || {}}>{headersList[key].title} {component}</th>
                                );
                            }
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {list.length > 0
                            ?
                            list.map((router) =>
                                <RouterRow
                                    query={query}
                                    key={router.serial}
                                    router={router}
                                    onDeleteRouter={onDeleteRouter}
                                    auth={auth}
                                />
                            )
                            :
                            <tr>
                                <td colSpan={listLen} className="text-center text-info">
                                    {query !== "" ? "No items match your search." : "No repeaters were found."}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

Table.propTypes = {
    list: PropTypes.array,
    sort: PropTypes.object,
    onSortChange: PropTypes.func,
    onDeleteRouter: PropTypes.func,
    query: PropTypes.string,
    auth: PropTypes.object
};

const RouterRow = (props) => {
    const {router, onDeleteRouter, auth, query} = props;

    const [showModal] = usePacketLossChartModal(router.serial, EQUIPMENT_TYPE_REPEATER, "Signal Charts");

    const getRouterLink = (forTable = true) => {
        const {router, query} = props;

        const text = forTable ? Helper.highlight(router.serial, query) : router.serial;

        return (router && router.serial)
            ? <Link
                to={`/network/routers/${router.serial}`}
                className="link link-primary"
                title="View Repeater"
                style={{margin: 0}}
                dangerouslySetInnerHTML={{__html: text}}
            />
            : <i className="ml-2">Not set</i>;
    };

    const getGatewayLink = (forTable = true) => {
        const {router, query} = props;

        const gateway = (router || {}).gateway || {};
        const gatewayName = gateway ? (gateway.name ? gateway.name + " (" + gateway.serial + ")" : gateway.serial) : "---";

        const text = forTable ? Helper.highlight(gatewayName, query) : gateway.serial;

        return (gateway && gateway.serial)
            ? <Link
                to={`/network/gateways/${gateway.serial}`}
                className="link link-primary"
                title="View Gateway"
                dangerouslySetInnerHTML={{__html: text}}
                style={{margin: 0}}
            />
            : <i className="ml-2">Not set</i>;
    };

    const getGatewayConnectivityTitle = (signal) => {
        switch (signal) {
            case SIGNAL_LABEL_SUCCESS:
                return "Good Connectivity";
            case SIGNAL_LABEL_WARNING:
                return "Moderate Connectivity";
            case SIGNAL_LABEL_DANGER:
                return "Poor Connectivity";
            default:
               return "N/A";
        }
    };

    return (
        <tr>
            <td>
                    <span
                        title={getGatewayConnectivityTitle(router.signal)}
                        className={`signal-lvl position-relative color-${router.signal}`}
                        style={{marginLeft: 0}}
                        onClick={showModal}
                    >
                        {router.signal === "default" ? "N/A" : <img src={`/assets/pic/icon-signal-level-${router.signal}.svg`} />}
                    </span>
                <SignalWarningTooltip
                    packetLossPercent={_get(router, "packetLossPercent")}
                    signalStrength={_get(router, "signalStrength")}
                    tooltipText={"This repeater has marginal connectivity and might degrade the overall network performance."}
                />
            </td>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(router.location || "---", query)}}/>
            <td>{getRouterLink()}</td>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(router.radio_address, query)}}/>
            <td>{getGatewayLink()}</td>
            <td>{_get(router, "last_seen")}</td>
            <td>
                <FirmwareVersion
                    version={router?.radio_version}
                    name={router?.serial}
                    isFirmwareExists={!!router?.firmwareExist}
                    description={router?.firmwareDescription}
                    shortDescription={router?.firmwareShortDescription}
                    type="Radio"
                />
            </td>
            <td>
                <div className="btn-group btn-group-sm">
                    {auth.userCan("editRouters") &&
                        <button className="link link-danger" onClick={() => onDeleteRouter(router)}>
                            <i className="fa fa-times"/>
                            <span>Delete</span>
                        </button>
                    }
                    <UncontrolledDropdown className="d-inline-block">
                        <DropdownToggle
                            tag={"button"}
                            className={"link link-primary"}
                            style={{minWidth: "1.5rem", borderRadius: 0, display: "inline-block", lineHeight: "1.6rem", cursor: "pointer", marginLeft: "10px"}}
                        >
                            <i className="fa fa-eye"/>
                            <span>Path</span>
                        </DropdownToggle>
                        <DropdownMenu right={true} className={"p-3"}>
                            <div>Repeater</div>
                            {getRouterLink(false)}
                            <hr className="mt-2 mb-2"/>
                            <div>Gateway</div>
                            {getGatewayLink(false)}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </td>
        </tr>
    );
};

RouterRow.propTypes = {
    router: PropTypes.object,
    query: PropTypes.string,
    onDeleteRouter: PropTypes.func,
    auth: PropTypes.object
};

export default withGlobalStore(Table);